import { ExpandableTable } from "@components/ExpandebleTable";
import {
  adtypeData,
  formatData,
  funnelData,
  productsColumns,
  productsData,
  targetingData,
} from "../../mock";
import { useEffect, useState } from "react";
import { TabsTypeList } from "@pages/AdsAnalitics/components/SanKey/utils";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import styles from "./styles.module.scss";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import { useKeywordStore } from "src/store/keyword.state";
import Search from "@assets/icons/search";
import StarKeyword from "@assets/icons/StarKeyword";
import { getStarColor } from "@components/ExpandebleTable/KeyTooltip";
import { Checkbox } from "antd";
import { PerformanceTable } from "../Performance";
import { Option } from "@components/Select";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { horizontalTargetData } from "@pages/AdsAnalitics/components/SanKey/Chart/chart.mock";

const getTabData = (tab: TypeTab) => {
  switch (tab) {
    case "Campaigns":
      return productsData;
    case "Ad Type":
      return adtypeData;
    case "Funnel":
      return funnelData;
    case "Target Type":
      return horizontalTargetData;
    case "Targeting":
      return targetingData;
    default:
      return productsData;
  }
};

const formattedKeys = (keys: string[]) => {
  return keys.map((key, index) => (
    <div className={styles.keys__container}>
      <Checkbox />
      <div className={styles.star}>
        <StarKeyword fill={getStarColor(index)} />
      </div>
      <span>{key}</span>
    </div>
  ));
};

export const CampaignsTable = () => {
  const { keyword, setKeywords } = useKeywordStore();
  const [tooltipTabsTitle, setTooltipTabsTitle] = useState("");
  const [tooltipKeysTitle, setTooltipKeysTitle] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [choosedKeyword, setChoosedKeyword] = useState("");
  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleKeywords = (value: string) => {
    setChoosedKeyword(value);
  };

  const handleTabsTooltip = (title: string) => {
    setTooltipTabsTitle(title);
  };

  const handleKeysTooltip = (title: string) => {
    setTooltipKeysTitle(title);
  };

  const [data, setData] = useState(
    formatData(
      getTabData(selectedTab),
      handleTabsTooltip,
      tooltipTabsTitle,
      tooltipKeysTitle,
      handleKeysTooltip,
      keyword,
      setKeywords,
      selectedTab === "Campaigns",
    ),
  );

  useEffect(() => {
    setData(
      formatData(
        getTabData(selectedTab),
        handleTabsTooltip,
        tooltipTabsTitle,
        tooltipKeysTitle,
        handleKeysTooltip,
        keyword,
        setKeywords,
        selectedTab === "Campaigns",
      ),
    );
  }, [tooltipTabsTitle, tooltipKeysTitle, keyword, selectedTab]);

  useEffect(() => {
    const fetchData = async () => {
      const productsService = new ProductsService();
      try {
        const data = await productsService.getOwnProducts();
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [asin]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <>
        <div className={styles.inputBox}>
          <h2 className={styles.asinText}>ASIN</h2>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => {
              setAsin(value);
            }}
            className={styles.asin}
          />
        </div>
      </>
      <TabsTypeList selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className={styles.filters}>
        <Input
          value={search}
          placeholder="Search a campaign..."
          onChange={setSearch}
          className={styles.input}
          icon={<Search />}
        />
        <Select
          value={filter}
          options={["All", "Active", "Inactive"]}
          onChange={handleFilter}
          placeholder="Filters"
        />
        {selectedTab === "Campaigns" && (
          <Select
            value={choosedKeyword}
            options={formattedKeys(keyword.keywords)}
            onChange={handleKeywords}
            className={styles.select}
            placeholder={
              <span className={styles.placeholder}>
                <StarKeyword fill="#5C5C5A" />
                Custom Keywords
              </span>
            }
          />
        )}

        <button className={styles.export__button}>EXPORT</button>
      </div>
      {selectedTab === "Performance" ? (
        <div>
          <PerformanceTable />
        </div>
      ) : (
        <ExpandableTable
          columns={productsColumns}
          data={data}
          rowSelection={rowSelection}
          withCustomScroll
        />
      )}
    </>
  );
};
