import { ExpandableTable } from "@components/ExpandebleTable";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { TabsTypeList } from "./Tabs";
import { getColumns, getData } from "./utils";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { campaignsData, performanceData } from "./mock";
import { adtypeData, funnelData, targetData } from "../../mock";
import { horizontalTargetData } from "@pages/AdsAnalitics/components/SanKey/Chart/chart.mock";
import { Select } from "@components/Select";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { Option } from "@components/Select";

export const WhatIfTable = () => {
  const { keys, setKeys, sankeyTab, setSankeyTab } = useAdsAnaliticsStore();
  const [selectedCampaignsKeys, setSelectedCampaignsKeys] = useState([]);
  const [selectedPerformanceKeys, setSelectedPerformanceKeys] = useState([]);
  const [selectedTargetingKeys, setSelectedTargetingKeys] = useState([]);
  const [selectedAddTypeKeys, setSelectedAddTypeKeys] = useState([]);
  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();

  const onSelectChange = (selectedRowKeys: any) => {
    let data;
    let colName;
    switch (sankeyTab) {
      case "Campaign":
        data = campaignsData;
        colName = "campaign";
        break;
      case "Ad Type":
        data = adtypeData;
        break;
      case "Funnel":
        data = funnelData;
        break;
      case "Target Type":
        data = horizontalTargetData;
        break;
      case "Performance":
        data = performanceData;
        colName = "performance";
        break;
      case "Targeting":
        data = targetData;
        colName = "targeting";
        break;
    }

    const flattenedArray = data.flatMap((item) => [
      item,
      ...(item.children || []),
    ]);

    const keysNames = flattenedArray
      .filter((item) => {
        return selectedRowKeys.includes(item.key) && item.key.includes("-");
      })
      .map((item) => item[colName]);

    setKeys({ ...keys, [colName]: keysNames });

    switch (sankeyTab) {
      case "Campaign":
        setSelectedCampaignsKeys(selectedRowKeys);
        break;
      case "Performance":
        setSelectedPerformanceKeys(selectedRowKeys);
        break;
      case "Ad Type":
        setSelectedAddTypeKeys(selectedRowKeys);
        break;
      default:
        setSelectedTargetingKeys(selectedRowKeys);
    }
  };

  console.log(keys);

  const rowSelection = {
    selectedRowKeys: (() => {
      switch (sankeyTab) {
        case "Campaign":
          return selectedCampaignsKeys;
        case "Performance":
          return selectedPerformanceKeys;
        case "Ad Type":
          return selectedAddTypeKeys;
        default:
          return selectedTargetingKeys;
      }
    })(),
    onChange: onSelectChange,
  };
  useEffect(() => {
    const fetchData = async () => {
      const productsService = new ProductsService();
      try {
        const data = await productsService.getOwnProducts();
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [asin]);
  return (
    <div className={styles.container}>
      <div className={styles.inputBox}>
        <h2 className={styles.asinText}>ASIN</h2>
        <Select
          value={asin}
          placeholder="Select asin"
          options={asinOptions}
          onChange={(value: string) => {
            setAsin(value);
          }}
          className={styles.asin}
        />
      </div>
      <TabsTypeList selectedTab={sankeyTab} setSelectedTab={setSankeyTab} />
      <ExpandableTable
        withCustomScroll
        columns={getColumns(sankeyTab)}
        data={getData(sankeyTab)}
        rowSelection={rowSelection}
      />
    </div>
  );
};
